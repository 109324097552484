export default {
  data() {
    return {
      validate: {
        accountInfo: {
          name: { isValid: true, errMsg: "" },
          mail: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          mobilePhone: { isValid: true, errMsg: "" },
        },
      },

      isValidForm: {
        accountInfo: true,
      },
    };
  },
  methods: {
    // アカウント情報 バリデートチェック
    accountInfoValidateCheck() {
      const v = this.validate.accountInfo;
      let isValidForm = true;

      if (!v.name.isValid) {
        v.name.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      // 追加モーダル：氏名の文字数の制限: 全角12文字以内のチェック
      if (this.form.name) {
        if (this.form.name.length > 12) {
          v.name.errMsg = "全角12文字以内で入力してください。";
          isValidForm = false;
        }
      }

      // 編集モーダル：氏名の文字数の制限: 全角12文字以内のチェック
      if (this.formEdit.name) {
        if (this.formEdit.name.length > 12) {
          v.name.errMsg = "全角12文字以内で入力してください。";
          isValidForm = false;
        }
      }

      if (!v.mail.isValid) {
        v.mail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if ((this.form.tel || this.formEdit.tel) && !v.tel.isValid) {
        v.tel.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if ((this.form.mobilePhone || this.formEdit.mobilePhone) && !v.mobilePhone.isValid) {
        v.mobilePhone.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      return isValidForm;
    },

    runValidate() {
      let isValidForm = false;
      this.isValidForm.accountInfo = this.accountInfoValidateCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
