export default {
  data() {
    return {
      form: {
        userTypeId: null,
        groupId: null,
        accountTypeId: 1, // 仮値
        isGenerateMember: false,
        name: "",
        mail: "",
        tel: "",
        mobilePhone: "",
      },
    };
  },
};
