export default {
  data() {
    return {
      formEdit: {
        memberId: "",
        name: "",
        accountType: {
          id: 1,
          name: "担当者",
        },
        mail: "",
        tel: "",
        mobilePhone: "",
      },
    };
  },
};
