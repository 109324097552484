<template>
  <div :class="hasIndent ? 'account-indent' : ''">
    <div class="l-lead-s title-wrap">
      <h1 :class="hasIndent ? 'c-lead icon indent' : 'c-lead'">
        {{ groupName }}<span class="counter" v-if="isCba || hasAddPermission">({{ accountList.length }})</span>
      </h1>
      <div v-if="hasAddPermission" class="c-btn secondary func add" @click="addMember">
        アカウント追加
      </div>
    </div>
    <div v-if="accountList.length" class="c-infoList__wrap">
      <div class="c-infoList l-box">
        <div class="c-infoList__row head">
          <div :class="cellName">氏名</div>
          <div class="c-infoList__cell md">メールアドレス</div>
          <div class="c-infoList__cell sm">電話番号</div>
          <div class="c-infoList__cell sm">携帯電話番号</div>
          <div class="c-infoList__cell cb"></div>
        </div>
        <div class="c-infoList__row list"
             v-for="(account, index) in accountList"
             :key="`user-type-${groupUserTypeId}-group-${groupId}-account-${index}`"
        >
          <div :class="cellName">
            {{ account.name }}<span class="c-badge-black" v-if="account.accountType.id === accountType.ADMIN && groupUserTypeId != userType.DISPOSAL_COMPANY && groupUserTypeId != userType.DISPOSAL_SITE">管理者</span>
          </div>
          <div class="c-infoList__cell md">{{ account.mail }}</div>
          <div class="c-infoList__cell sm">{{ account.tel | blankText }}</div>
          <div class="c-infoList__cell sm">{{ account.mobilePhone | blankText }}</div>
          <div class="c-infoList__cell cb"></div>
        </div>
      </div>

      <!-- アクションボタン -->
      <div class="c-infoList__floated">
        <div class="c-infoList__cell"
            v-for="(account, index) of accountList"
            :key="`user-type-${groupUserTypeId}-group-${groupId}-action-${index}`">
          <div v-if="loginUser.id !== account.memberId" class="c-accordionList__cell right">
            <input type="checkbox" class="c-accordionList__acc"
                   :id="`user-type-${groupUserTypeId}-group-${groupId}-action-${index}`"
                   v-model="account.isOpen" />
            <div class="c-accordionList__body" style="margin-right: -1rem">
              <div class="c-accordionList__cell ml-1" @click="editMember(account)">
                <a class="c-btn-icon edit secondary sm"/>
              </div>
            </div>
            <div class="c-accordionList__body">
              <div class="c-accordionList__cell ml-1" @click="deleteMember(account)">
                <a class="c-btn-icon delete secondary sm"/>
              </div>
            </div>
            <label class="c-accordionList__cell ml-1 c-accordionList__acc__lbl"
                   :for="`user-type-${groupUserTypeId}-group-${groupId}-action-${index}`">
              <a class="c-btn-icon some secondary sm"/>
            </label>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import {DOMAIN} from "@/const";

export default {
  props: {
    groupId: {
      type: Number,
    },
    groupName: {
      type: String,
    },
    groupUserTypeId: {
      type: Number,
    },
    accountList: {
      type: Array,
    },
  },
  data() {
    return {
      loginUser: this.$store.getters["userInfo/get"],
      isCba: this.$store.getters["userInfo/isCba"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      accountType: DOMAIN.ACCOUNT_TYPE,
      userType: DOMAIN.USER_TYPE,
    };
  },
  computed: {
    hasIndent() {
      return !(this.groupUserTypeId === this.userType.GENERATE_CONTRACTOR
          || this.groupUserTypeId === this.userType.COLLECT_COMPANY);
    },
    hasAddPermission() {
      return Math.floor(this.groupUserTypeId / this.loginUser.userTypeId) === 1 && this.loginUser.userTypeId <= this.groupUserTypeId;
    },
    cellName() {
      let cellClass = "c-infoList__cell account-name";
      switch (this.groupUserTypeId) {
        case this.userType.GENERATE_COMPANY: return cellClass += " indent-1";
        case this.userType.GENERATE_FACTORY: return cellClass += " indent-2";
        case this.userType.GENERATE_STORE: return cellClass += " indent-3";
        case this.userType.COLLECT_FACTORY: return cellClass += " indent-1";
        case this.userType.COLLECT_STORE: return cellClass += " indent-2";
        default: return cellClass;
      }
    }
  },
  methods: {
    addMember() {
      this.$emit("add", this.groupId, this.groupName, this.groupUserTypeId);
    },
    editMember(account) {
      this.$emit("edit", account, this.groupName, this.groupUserTypeId);
    },
    deleteMember(account) {
      this.$emit("delete", account.memberId, account.name);
    },
  }
}
</script>

<style scoped lang="scss">
.title-wrap {
  display: flex;
  justify-content: space-between;
}
.c-btn-icon.delete {
  display: inline-flex;
  margin-left: 1rem;
}
.account-indent {
  padding-left: 3rem;
}
.account-name {
  width: 42rem;
  &.indent-1 {
    width: 39rem;
  }
  &.indent-2 {
    width: 36rem;
  }
  &.indent-3 {
    width: 33rem;
  }
}
</style>
