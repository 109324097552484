export default {
  data() {
    return {
      selectCompanyList: [], //入力テキストで絞る排出事業者選択肢
      companyList: [],
    };
  },
  methods: {
    // 事業者選択肢取得API
    getCompanyApi() {
      const isAuthority = this.isCba || this.isGenerateContract
      if (isAuthority) {
        this.getCompanySelection().then((data) => {
          this.companyList = data;
          this.selectCompanyList = data;
        }).catch(() => {})
      }
    }

  },
};
