<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="アカウント一覧" />
    <AppSideMenu />
    <main class="l-main">

      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, index) in shorten()">
                <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
                  {{ s.key }}<div class="data"> : {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box">
            <h3 class="c-lead-s-black icon">検索</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <div v-if="isCba || isGenerateContract">
                  <span class="text-label">事業者</span>
                    <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="すべて" class="c-input xlg_md" :items="this.selectCompanyList" :min-len="0" :wait="300" @update-items='updateCompanyList' v-model="selectedCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                    </v-autocomplete>
                </div>
                <InputText
                class="c-input xlg_md"
                    :value.sync="keySearch"
                    inputType="text"
                    placeholder="氏名、メールアドレスを入力"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                  <span class="text-label">キーワード</span>
                </InputText>
                <template v-if="!isDisposer">
                  <div
                      v-for="(item, index) in accountTypeList"
                      :key="`first-${index}`"
                      class="c-input"
                  >
                    <div v-if="index === 0" class="checkbox-label">権限</div>
                    <div class="c-checkbox">
                      <input
                          :id="item.name"
                          type="checkbox"
                          v-model="item.check"
                      />
                      <label class="c-checkbox__label" :for="item.name">
                        <span class="c-checkbox__box"></span>
                        {{ item.name }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="reset">条件をリセット</div>
            <div class="c-btn primary small" @click="search">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <template v-if="isInit" v-cloak>
        <div class="l-container">
          <div class="l-lead-s title-wrap">
            <h1 class="c-lead">検索結果は検索ボタンをクリックした後に表示されます。</h1>
          </div>
        </div>
      </template>

      <template v-else>
        <!-- 排出事業者セクション -->
        <div class="l-container" v-for="(generateContractGroup, g1) in groupMemberList.generateList" :key="`g-${g1}`">
          <!-- 排出契約 -->
          <AccountList v-for="(generateContract, g2) in generateContractGroup.generateContractAccountList"
                       :key="`g-${g1}-${g2}`"
                       :group-id="generateContract.generateContractId"
                       :group-name="generateContract.generateContractName"
                       :group-user-type-id="userType.GENERATE_CONTRACTOR"
                       :account-list="generateContract.accountList"
                       @add="addMember" @edit="editMember" @delete="deleteMember">
            <!-- 排出契約 > 排出事業者 -->
            <AccountList v-for="(generateCompany, g3) in generateContract.generateCompanyAccountList"
                         :key="`g-${g1}-${g2}-${g3}`"
                         :group-id="generateCompany.generateCompanyId"
                         :group-name="generateCompany.generateCompanyName"
                         :group-user-type-id="userType.GENERATE_COMPANY"
                         :account-list="generateCompany.accountList"
                         @add="addMember" @edit="editMember" @delete="deleteMember">
              <!-- 排出契約 > 排出事業者 > 排出事業所 -->
              <AccountList v-for="(generateFactory, g4) in generateCompany.generateFactoryAccountList"
                           :key="`g-${g1}-${g2}-${g3}-${g4}`"
                           :group-id="generateFactory.generateFactoryId"
                           :group-name="generateFactory.generateFactoryName"
                           :group-user-type-id="userType.GENERATE_FACTORY"
                           :account-list="generateFactory.accountList"
                           @add="addMember" @edit="editMember" @delete="deleteMember">
                <!-- 排出契約 > 排出事業者 > 排出事業所 > 排出事業場 -->
                <AccountList v-for="(generateStore, g5) in generateFactory.generateStoreAccountList"
                             :key="`g-${g1}-${g2}-${g3}-${g4}-${g5}`"
                             :group-id="generateStore.generateStoreId"
                             :group-name="generateStore.generateStoreName"
                             :group-user-type-id="userType.GENERATE_STORE"
                             :account-list="generateStore.accountList"
                             @add="addMember" @edit="editMember" @delete="deleteMember"/>
              </AccountList>
            </AccountList>
          </AccountList>

          <!-- 区切り線(排出契約グループ) -->
          <div class="divider" v-if="g1 < groupMemberList.generateList.length - 1 || groupMemberList.collectList.length"/>
        </div>

        <!-- 運搬事業者セクション -->
        <div class="l-container" v-if="groupMemberList.collectList.length">
          <!-- 運搬事業者 -->
          <AccountList v-for="(collectCompany, c1) in groupMemberList.collectList"
                       :key="`c-${c1}`"
                       :group-id="collectCompany.collectCompanyId"
                       :group-name="collectCompany.collectCompanyName"
                       :group-user-type-id="userType.COLLECT_COMPANY"
                       :account-list="collectCompany.accountList"
                       :is-my-group="isCollector"
                       @add="addMember" @edit="editMember" @delete="deleteMember">
            <!-- 運搬事業者 > 運搬事業所 -->
            <AccountList v-for="(collectFactory, c2) in collectCompany.collectFactoryAccountList"
                         :key="`c-${c1}-${c2}`"
                         :group-id="collectFactory.collectFactoryId"
                         :group-name="collectFactory.collectFactoryName"
                         :group-user-type-id="userType.COLLECT_FACTORY"
                         :account-list="collectFactory.accountList"
                         :is-my-group="isCollector"
                         @add="addMember" @edit="editMember" @delete="deleteMember">
              <!-- 運搬事業者 > 運搬事業所 > 運搬営業所 -->
              <AccountList v-for="(collectOffice, c3) in collectFactory.collectOfficeAccountList"
                           :key="`c-${c1}-${c2}-${c3}`"
                           :group-id="collectOffice.collectOfficeId"
                           :group-name="collectOffice.collectOfficeName"
                           :group-user-type-id="userType.COLLECT_STORE"
                           :account-list="collectOffice.accountList"
                           :is-my-group="isCollector"
                           @add="addMember" @edit="editMember" @delete="deleteMember"/>
            </AccountList>

            <!-- 区切り線(運搬事業者) -->
            <div class="divider" v-if="c1 < groupMemberList.collectList.length - 1"/>
          </AccountList>
        </div>

        <!-- 処分事業者セクション -->
        <div class="l-container" v-if="groupMemberList.disposalList.length">
          <!-- 処分事業者 -->
          <AccountList v-for="(disposalCompany, d1) in groupMemberList.disposalList"
                       :key="`d-${d1}`"
                       :group-id="disposalCompany.disposalCompanyId"
                       :group-name="disposalCompany.disposalCompanyName"
                       :group-user-type-id="userType.DISPOSAL_COMPANY"
                       :account-list="disposalCompany.accountList"
                       :is-my-group="isDisposer"
                       @add="addMember" @edit="editMember" @delete="deleteMember">
            <!-- 処分事業者 > 処分事業所 -->
            <AccountList v-for="(disposalSite, d2) in disposalCompany.disposalSiteAccountList"
                       :key="`d-${d2}`"
                       :group-id="disposalSite.disposalSiteId"
                       :group-name="disposalSite.disposalSiteName"
                       :group-user-type-id="userType.DISPOSAL_SITE"
                       :account-list="disposalSite.accountList"
                       :is-my-group="isDisposer"
                       @add="addMember" @edit="editMember" @delete="deleteMember"/>
            <div class="divider" v-if="d1 < groupMemberList.disposalList.length - 1"/>
          </AccountList>
        </div>
      </template>
    </main>

    <!-- 削除確認のモーダル -->
    <div v-if="isDisplayConfirmDeleteModal" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <ErrorMessage :errMsgs="errMsgs" />
          アカウント「{{selectedMemberName}}」を削除します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayConfirmDeleteModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="deleteHandler">OK</div>
        </div>
      </div>
    </div>
    <!-- アカウント追加のモーダル -->
    <div v-if="isDisplayAdd" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">アカウント登録</h5>
          <span
            class="c-modal__head__close"
            @click="
              isDisplayAdd = false;
              selectedName = null;
              clearForm();
            "
          ></span>
        </div>
        <div class="c-modal__body">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">アカウント情報</h3>
            <div class="c-form__reqTxt l-item">：入力必須</div>
          </div>
          <ErrorMessage :errMsgs="errMsgs" />
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>所属先</label>
              </div>
              <div class="c-inputWrap__items">
                {{ selectedName }}
              </div>
            </div>
            <div v-if="isDisplayAccountType" class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>権限</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <div class="c-radio" v-for="at in accountTypeList" :key="'account-type-' + at.id">
                    <input
                      :id="'account-type-' + at.id"
                      type="radio"
                      name="account-type-id"
                      :value="at.id"
                      v-model="form.accountTypeId"
                    />
                    <label class="c-radio__label" :for="'account-type-' + at.id">
                      <span class="c-radio__box"></span>
                      {{ at.name }}
                    </label>
                  </div>
                </div>
                <!-- 排出アカウントのみ表示 -->
                <template v-if="form.userTypeId >= 20 && form.userTypeId < 30">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-checkbox">
                      <input
                          id="is-generate-member"
                          type="checkbox"
                          v-model="form.isGenerateMember"
                      />
                      <label class="c-checkbox__label" for="is-generate-member">
                        <span class="c-checkbox__box"></span>
                        引き渡し担当者
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.name"
                  inputTyle="text"
                  placeholder="氏名"
                  tooltip="全角12文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="validate.accountInfo.name.isValid"
                  :errMsg.sync="validate.accountInfo.name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.mail"
                  inputTyle="text"
                  placeholder="メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.accountInfo.mail.isValid"
                  :errMsg.sync="validate.accountInfo.mail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.tel"
                  inputTyle="text"
                  placeholder="電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.tel.isValid"
                  :errMsg.sync="validate.accountInfo.tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>携帯電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.mobilePhone"
                  inputTyle="text"
                  placeholder="携帯電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.mobilePhone.isValid"
                  :errMsg.sync="validate.accountInfo.mobilePhone.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="
              isDisplayAdd = false;
              selectedName = null;
              clearForm();
            "
          >
            キャンセル
          </div>
          <div class="c-btn primary small" @click="addHandler()">登録</div>
        </div>
      </div>
    </div>
    <!-- アカウント編集のモーダル -->
    <div v-if="isDisplayEdit" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">アカウント編集</h5>
          <span
            class="c-modal__head__close"
            @click="
              isDisplayEdit = false;
              selectedName = null;
            "
          ></span>
        </div>
        <div class="c-modal__body">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">アカウント情報</h3>
            <div class="c-form__reqTxt l-item">：入力必須</div>
          </div>
          <ErrorMessage :errMsgs="errMsgs" />
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>所属先</label>
              </div>
              <div class="c-inputWrap__items">
                {{ selectedName }}
              </div>
            </div>
            <div v-if="isDisplayAccountType" class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>権限</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <div class="c-radio" :class="{ disabled: isAuthorityEdit }" v-for="at in accountTypeList" :key="'edit-account-type-' + at.id">
                    <input
                        :id="'edit-account-type-' + at.id"
                        type="radio"
                        name="account-type-id"
                        :value="at.id"
                        v-model="formEdit.accountType.id"
                    />
                    <label class="c-radio__label" :for="'edit-account-type-' + at.id">
                      <span class="c-radio__box"></span>
                      {{ at.name }}
                    </label>
                  </div>
                </div>
                <!-- 排出アカウントのみ表示 -->
                <template v-if="formEdit.userTypeId >= 20 && formEdit.userTypeId < 30">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-checkbox">
                      <input
                          id="edit-is-generate-member"
                          type="checkbox"
                          v-model="formEdit.isGenerateMember"
                      />
                      <label class="c-checkbox__label" for="edit-is-generate-member">
                        <span class="c-checkbox__box"></span>
                        引き渡し担当者
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.name"
                  inputTyle="text"
                  placeholder="氏名"
                  tooltip="全角12文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="validate.accountInfo.name.isValid"
                  :errMsg.sync="validate.accountInfo.name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.mail"
                  inputTyle="text"
                  placeholder="メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.accountInfo.mail.isValid"
                  :errMsg.sync="validate.accountInfo.mail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.tel"
                  inputTyle="text"
                  placeholder="電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.tel.isValid"
                  :errMsg.sync="validate.accountInfo.tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>携帯電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.mobilePhone"
                  inputTyle="text"
                  placeholder="携帯電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.mobilePhone.isValid"
                  :errMsg.sync="validate.accountInfo.mobilePhone.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="
              isDisplayEdit = false;
              selectedName = null;
            "
          >
            キャンセル
          </div>
          <div class="c-btn primary small" @click="editHandler()">変更</div>
        </div>
      </div>
    </div>
    <!-- アカウント編集確認モーダル -->
    <div class="c-modal" v-show="isDisplayConfirmEditModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          アカウント情報を編集します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div
              class="c-btn secondary small"
              @click="isDisplayConfirmEditModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="editConfirmHandler()">
            はい
          </div>
        </ModalFooterButtons>
      </div>
    </div>
    <!-- アカウント登録確認モーダル -->
    <div class="c-modal" v-show="isDisplayConfirmAddModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          アカウント情報を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div
              class="c-btn secondary small"
              @click="isDisplayConfirmAddModal = false; clearForm();"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="addConfirmHandler()">
            はい
          </div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import { API_URL, DOMAIN } from "@/const";
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import validation from "@/mixin/validation";
import formValue from "./mixins/formValue";
import formValueEdit from "./mixins/formValueEdit";
import formValidation from "./mixins/form/formValidation";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import AccountList from "./components/AccountList";
import common from "@/mixin/common";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import callApi from "./mixins/callApi";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  name: "account",
  components: {
    AccountList,
    ModalFooterButtons,
    InputText,
  },
  mixins: [validation, formValue, formValueEdit, formValidation, common, callApi, selectionLocalStorage],
  data() {
    return {
      isInit: true,
      isDisplaySearch: false,
      accountTypeList: [],
      keySearch: "",
      groupMemberList: {
        generateList: [],
        collectList: [],
        disposalList: [],
      },
      isDisplayConfirmDeleteModal: false,
      selectedMemberId: null,
      selectedMemberName: null,
      isDisplayAdd: false,
      isDisplayEdit: false,
      isDisplayConfirmEditModal: false,
      isDisplayConfirmAddModal: false,
      isDisplayAccountType: false,
      selectedName: null,
      errMsgs: [],
      isAuthorityEdit: false,
      processing: false,
      userType: DOMAIN.USER_TYPE,
      loginUser: this.$store.getters["userInfo/get"],
      isCba: this.$store.getters["userInfo/isCba"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      selectTemplate: SelectItemTemplate,
      autoSelect: false,
      selectedCompany: null, //選択した事業者
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isGenerateContract: this.$store.getters["userInfo/isGenerateContractor"],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getCompanyApi();

    // アカウント権限種別取得API
    axios
      .get(API_URL.DOMAIN.ACCOUNT_TYPE)
      .then((res) => {
        this.accountTypeList = res.data.accountTypeList;
        // 検索用のプロパティを追加
        this.accountTypeList.forEach(function (val) {
          val["check"] = true;
        });
      })
  },
  methods: {
    getParams() {
      var accountTypeId = [];
      var checked = false;
      for (let index in this.accountTypeList) {
        if (this.accountTypeList[index].check === true) {
          accountTypeId.push(this.accountTypeList[index].id);
          checked = true;
        }
      }
      if (!checked) {
        for (let index in this.accountTypeList) {
          accountTypeId.push(this.accountTypeList[index].id);
        }
      }
      return accountTypeId;
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    shorten() {
      const result = [];
      const checkedAccountType = this.accountTypeList.filter(m => m.check);

      if (this.keySearch) result.push({ key: "キーワード", value: this.keySearch });
      if (this.selectedCompany && this.selectedCompany.id) result.push({ key: "事業者", value: this.selectedCompany.name });
      if (checkedAccountType.length > 0) {
        result.push({
          key: "権限",
          value: checkedAccountType.map((acc) => acc.name).join("、")
        });
      }
      return result;
    },
    search() {
      this.isInit = false;
      const params = {
        keysearch: this.keySearch,
        accountTypeId: this.getParams().join(','),
        companyId: this.selectedCompany !== null ? this.selectedCompany.id : null,
        companyType: this.selectedCompany !== null ? this.selectedCompany.companyType : null
      };
      // アカウント一覧検索のAPI
      axios
        .get(`${API_URL.ACCOUNT_MANAGEMENT.ACCOUNT}`, { params })
        .then((res) => {
          this.groupMemberList = res.data.groupMemberList;
        });
    },
    initSearch() {
      const newAccoutType = []
      for (const item of this.accountTypeList) {
        newAccoutType.push({
          id: item.id,
          name: item.name,
          check: false,
        });
      }
      this.accountTypeList = newAccoutType
    },
    reset() {
      this.initSearch();
      this.keySearch = "";
      this.selectedCompany = "";
    },
    clearForm() {
      this.form.userTypeId = null;
      this.form.groupId = null;
      this.form.accountTypeId = 1;
      this.form.isGenerateMember = false;
      this.form.name = "";
      this.form.mail = "";
      this.form.tel = "";
      this.form.mobilePhone = "";
    },
    editMember(account, groupName, groupUserTypeId) {
      this.errMsgs = [];
      this.selectedName = groupName;
      this.selectedMemberId = account.memberId;
      this.formEdit.memberId = account.memberId;
      this.formEdit.accountType = account.accountType;
      this.formEdit.isGenerateMember = account.isGenerateMember;
      this.formEdit.name = account.name;
      this.formEdit.mail = account.mail;
      this.formEdit.tel = account.tel ? account.tel : "";
      this.formEdit.mobilePhone = account.mobilePhone ? account.mobilePhone : "";
      this.formEdit.userTypeId = groupUserTypeId;
      this.isDisplayEdit = true;
      this.isAuthorityEdit = this.loginUser.id === account.memberId && !this.isCba;
      this.handleDisplayAccountType(groupUserTypeId);
    },
    editHandler() {
      if (!this.runValidate()) {
        this.errMsgs.push("入力に誤りがあります。");
        return;
      }

      this.isDisplayEdit = false;
      this.isDisplayConfirmEditModal = true;
    },
    editConfirmHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      // 氏名を全角に置換する
      this.formEdit.name = this.toFullWidthString(this.formEdit.name);
      axios.put(API_URL.ACCOUNT_MANAGEMENT.EDIT + this.selectedMemberId, this.formEdit)
        .then(() => {
          this.search();
          this.isDisplayConfirmEditModal = false;
          this.processing = false; // 2重submit解除
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isDisplayConfirmEditModal = false;
          this.isDisplayEdit = true;
          this.processing = false; // 2重submit解除
        });
    },
    deleteMember(memberId, memberName) {
      this.selectedMemberId = memberId;
      this.selectedMemberName = memberName;
      this.isDisplayConfirmDeleteModal = true;
    },
    deleteHandler() {
      this.errMsgs = [];
      axios.delete(API_URL.ACCOUNT_MANAGEMENT.DELETE + this.selectedMemberId)
        .then(() => {
          this.search();
          this.selectedMemberId = null;
          this.selectedMemberName = null;
          this.isDisplayConfirmDeleteModal = false;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    addMember(groupId, groupName, groupUserTypeId) {
      this.errMsgs = [];
      this.selectedName = groupName;
      this.form.groupId = groupId;
      this.form.userTypeId = groupUserTypeId;
      // 元のvalidateのエラーも消します
      this.resetValidateAccountInfo()
      this.isDisplayAdd = true;
      this.handleDisplayAccountType(groupUserTypeId);
    },

    // モーダル変わると、元のvalidateのエラーも消します
    resetValidateAccountInfo() {
      this.validate = {
        accountInfo: {
          name: { isValid: true, errMsg: "" },
          mail: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          mobilePhone: { isValid: true, errMsg: "" },
        },
      }
    },

    addHandler() {
      if (!this.runValidate()) {
        this.errMsgs.push("入力に誤りがあります。");
        return;
      }

      this.isDisplayAdd = false;
      this.isDisplayConfirmAddModal = true;
    },
    addConfirmHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      // 氏名を全角に置換する
      this.form.name = this.toFullWidthString(this.form.name)
      axios
        .post(`${API_URL.ACCOUNT_MANAGEMENT.POST}`, { accountCreateList: [this.form] })
        .then(() => {
          this.clearForm();
          this.search();
          this.isDisplayConfirmAddModal = false;
          this.processing = false; // 2重submit解除
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isDisplayConfirmAddModal = false;
          this.isDisplayAdd = true;
          this.processing = false; // 2重submit解除
        });
    },

    getLabel(item) {
      if (item) {
        return item.name
      }
      return ''
    },

    updateCompanyList(text) {
      if (!text || text.length === 0) {
        //全ての事業者の選択肢を表示する
        this.selectCompanyList = this.companyList
        return
      }
      this.selectCompanyList = this.companyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },

    handleDisplayAccountType(groupUserTypeId) {
      this.isDisplayAccountType = groupUserTypeId != this.userType.DISPOSAL_COMPANY && 
          groupUserTypeId != this.userType.DISPOSAL_SITE
    },
  },
};
</script>

<style scoped>
.title-wrap {
  display: flex;
  justify-content: space-between;
}
.text-label {
  font-size: 1.2rem;
}
.checkbox-label {
  font-size: 1.2rem;
  margin-bottom: 0.9rem;
}
.checkbox-wrap {
  margin-left: 4rem;
}
.search-wrap {
  align-items: flex-end;
}
.c-btn-icon.delete {
  display: inline-flex;
  margin-left: 1rem;
}
.divider {
  border-bottom: 1px solid #cbd0d8;
  margin: 4rem 0;
}
</style>
